.App {
  text-align: center;
}

.App-header {
  background-color: #222831;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EEEEEE;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Setting-Container { 
  background-color: #393E46;
  width: 50vw;
  margin-top: 120px;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
}

.Setting-Component { 
  padding: 10px;
}

.Setting-Value { 
  width: 20px;
}

button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.Setting-Button { 
  background-color: #DDB83B;
  width: 30px;
  line-height: 30px;
  color: #EEEEEE;
  border-radius: 20px;
}

.Setting-Button:hover { 
  transition: all .2s;
  opacity: 0.6;
}

.Setting-Start-Button { 
  margin-top: 60px;
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  color: #EEEEEE;
  font-weight: bold;
  border-radius: 50px;
  background-color: #DDB83B;
}

.Setting-Start-Button:hover { 
  transition: all .2s;
  opacity: 0.6;
}

.Anzan-Text { 
  font-size: 120px;
}

.Keyboard-Container { 
  /* display: flex; */
  width: 70%;
  justify-content: space-evenly;
}

.Numpad-Item { 
  background-color: gray;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #EEEEEE;
}

.Keyboard-Container :hover { 
  background-color: #909090;
  cursor: pointer;
  transition: all .2s;
}

.In-Container { 
  margin-top: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.Number-Container { 
  height: 10vh;
  margin-bottom: 30%;
}

.Answer-Number { 
  font-size: 80px;
}

.Container { 
  width: 70vw;
}

.Result-Title { 
  padding-bottom: 40px;
}

.Result-Details-Key { 
  text-align: left;
}

.Result-Details-Value { 
  text-align: right;
}

.Result-Details-Row { 
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 2px solid #EEEEEE;
  border-left: 2px solid #EEEEEE;
}

.Bottom-Border { 
  border-bottom: 2px solid #EEEEEE;
}

.Top-Border-Radius { 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Bottom-Border-Radius { 
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Top-Border { 
  border-top: 2px solid #EEEEEE;
}

@media screen and (max-width: 580px) {
	/* 480px以下に適用されるCSS（スマホ用） */
  .Container { 
    width: 90vw;
  }
}

@media screen and (min-width: 1200px) {
  .Container { 
    width: 30vw;
  }
}

.Result-Button { 
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  width: 40%;
  color: #EEEEEE;
  border: 2px solid #EEEEEE;
  border-radius: 40px;
}

.Result-Button:hover { 
  cursor: pointer;
  background-color: #909090;
  transition: all .2s;
}

.Result-Button-Container { 
  margin-top: 20px;
}

.Subtitle { 
  font-size: 20px;
  color: rgba(255, 255, 255, 0.598)
}

.App-Store { 
  margin-top: 40px;
}